import DeleteIcon from '../../assets/icons/delete.svg';

import { ref, getDownloadURL, deleteObject } from 'firebase/storage';
import { ref as dbRef, set } from 'firebase/database';
import { database, storage } from '../../firebase';
import { useEffect } from 'react';

const DashImage = (props) => {

    let index = props.index;
    let group_index = props.group_index;
    let data = props.image;

    const imageRef = ref(storage, `/auth_images/${group_index}/${data}`);
    const dbImageRef = dbRef(database, `/new_groups/${group_index}/imgs/${index}`);

    useEffect(() => {
        getDownloadURL(imageRef).then((url) => {
            document.getElementById(`dwgii-${index}`).setAttribute('src', url);
        })
        .catch((error) => {
            console.log(error);
        });
    }, []);

    function deleteImage() {
        if(window.confirm("Biztosan törölni szeretné ezt a képet?")) {
            set(dbImageRef, null);
            deleteObject(imageRef).then(() => {
                console.log("File successfully deleted.");
            }).catch((error) => {
                console.log(error);
            });
        }
    }

    return (
        <div className="dwg-image" id={`dwgi-${index}`}>
            <img src="" alt="" className='dwgii' id={`dwgii-${index}`} />
            <div className="dwgi-name">
                <img className='delete-icon' src={DeleteIcon} alt="" onClick={deleteImage}/>
            </div>
        </div>
    )
}

export default DashImage;