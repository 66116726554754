import '../css/navbar.css';
import NavLink from './NavLink';
import { Link } from 'react-scroll';

const Navbar = () => {
    return (
        <>
            <div className='navbar desktop'>
                <Link to="titlescreen" smooth={true} duration={1000} offset={0}>
                    <div className='nb-title'>
                        <div className='nb-logo'></div>
                        <p className='f16 noselect'>NAGY JENŐ ÓVODA</p>
                    </div>
                </Link>
                <div className='nb-links'>
                    <Link to="foundation-card" smooth={true} duration={1000} offset={-120}>
                        <NavLink title='ALAPÍTVÁNY' class={'nb-link'} size={30} />
                    </Link>
                    <Link to="groups" smooth={true} duration={1000} offset={-120}>
                        <NavLink title='CSOPORTJAINK' class={'nb-link'} size={30} />
                    </Link>
                    <Link to="contact" smooth={true} duration={1000} offset={-120}>
                        <NavLink title='KAPCSOLAT' class={'nb-link'} size={30} />
                    </Link>
                </div>
            </div>

            <div className='navbar mobile'>
                <div className='nb-title'>
                    <div className='nb-logo'></div>
                    <p className='f16 noselect'>NAGY JENŐ ÓVODA</p>
                </div>
                {/* <div className='menu-toggle'>
                    
                </div>
                <div className='nb-links-menu'>
                    <Link to="foundation-card" smooth={true} duration={1000} offset={-120}>
                        <NavLink title='ALAPÍTVÁNY' class={'nb-link'} size={30} />
                    </Link>
                    <Link to="groups" smooth={true} duration={1000} offset={-120}>
                        <NavLink title='CSOPORTJAINK' class={'nb-link'} size={30} />
                    </Link>
                    <Link to="contact" smooth={true} duration={1000} offset={-120}>
                        <NavLink title='KAPCSOLAT' class={'nb-link'} size={30} />
                    </Link>
                </div> */}
            </div>
        </>
    )
}

export default Navbar