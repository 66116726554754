import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import HomePage from './pages/HomePage';
import AdminPage from './pages/AdminPage';

function App() {

    return (
        <BrowserRouter>
            <Routes>
                <Route exact path='/' element={ <HomePage title='Nagy Jenő Óvoda | Tárt karokkal várunk!'/> } />
                <Route exact path='/admin' element={ <AdminPage title='Nagy Jenő Óvoda | Admin'/> } />
                {/* <Route path='*' element={ <Navigate to='/' /> }/> */}
            </Routes>
        </BrowserRouter>
    )
}

export default App
