const AboutSection = (props) => {

    const { id } = props;

    return (
        <>
            { id === 0 ?

            <div className='a-section'>
                <p className='f36'>EGÉSZSÉGES ÉLETMÓD</p>
                <p className='f16-p'>Tornatermünk felszerelése korszerű. Minden szempontból megfelel a gyermekek mozgásának, testi képességeinek fejlesztésére. Óvodánk melegítőkonyhával rendelkezik.</p>
                <div className='a-section-text'>
                    <p className='f16-p'> - Heti 3 alkalommal mozgásfoglalkozás a tornateremben</p>
                    <p className='f16-p'> - Mindennapos testnevelés keretén belül zenés torna az udvaron</p>
                    <p className='f16-p'> - Sószoba heti két alkalommal egészségmegőrzés céljából</p>
                    <p className='f16-p'> - Havi rendszerességgel kirándulás, túrázás</p>
                    <p className='f16-p'> - Őszi-tavaszi-téli egészséghetek szervezésével az egészséges táplálkozás, egészségmegőrzés, változatos mozgásos játékok (láb- és gerinctorna) tevékenység központú megközelítése</p>
                    <p className='f16-p'> - Vízhez szoktatás (nagycsoportban)</p>
                    <p className='f16-p'> - Ismerkedés a téli sportokkal, szánkózás</p>
                </div>
            </div>

            : id === 1 ?

            <div className='a-section'>
                <p className='f36 desktop'>TEHETSÉGGONDOZÁS</p>
                <p className='f36 mobile'>TEHETSÉG-GONDOZÁS</p>
                <p className='f16-p'>Mind személyi, mind tárgyi feltételeink adottak, hogy a differenciált nevelésen belül, játékosan, alapozó jelleggel, a gyermekekben lévő értékes képességeket kibontakoztassuk.</p>
                <div className='a-section-text'>
                    <p className='f16-p'> - Mozgáskotta módszer alkalmazása, amely komplex mozgás,- és személyiségfejlesztő program</p>
                    <p className='f16-p'> - Változatos zenei hangszerek készítése, megismertetése</p>
                    <p className='f16-p'> - Néptánc alapjainak lerakása</p>
                    <p className='f16-p'> - Zenehallgatási anyag folyamatos bővítése</p>
                    <p className='f16-p'> - Óvodai ünnepeken a kiemelkedő képességű gyermekek szereplése.</p>
                    <p className='f16-p'> - Gyermekrajz pályázatokon való részvétel</p>
                    <p className='f16-p'> - Csoportszoba dekorálása gyermeki munkával</p>
                    <p className='f16-p'> - Óvodán belüli kiállítás</p>
                </div>
            </div>

            :

            <div className='a-section'>
                <p className='f36 desktop'>KÖRNYEZETTUDATOSSÁG</p>
                <p className='f36 mobile'>KÖRNYEZET-TUDATOSSÁG</p>
                <div className='a-section-text'>
                    <p className='f16-p'> - Havonkénti kirándulások alkalmával, természetvédelemre, a természet szeretetére nevelés</p>
                    <p className='f16-p'> - Minél több, életszerű helyzet teremtése ahol, pl. Misina Állatvédő Egyesület- tapasztalatokat tudnak szerezni az állatvédelemről, állatgyógyításról, állatetetésről</p>
                    <p className='f16-p'> - Ismerkedés a védett állatokkal, növényekkel természet közelben: Pintérkert</p>
                    <p className='f16-p'> - Fűszerkerti növények gondozása, ápolása</p>
                    <p className='f16-p'> - Erdei Óvoda (Mókus suli) a gyermekek képzett vezetővel tanösvényeket járnak végig</p>
                    <p className='f16-p'> - Elemgyűjtés az óvodában</p>
                    <p className='f16-p'> - Az egész óvodában szelektív hulladékgyűjtés</p>
                    <p className='f16-p'> - Óvoda udvarán található növények gondozása</p>
                    <p className='f16-p'> - Ismerkedés a város nevezetességeivel</p>
                </div>
            </div>
        }
        </>
    )
}

export default AboutSection