import DashWorker from './DashWorker';

import ChevronDownIcon from '../../assets/icons/chevron-down.svg';
import { useState } from 'react';
import DashImage from './DashImage';
import { database, storage } from '../../firebase';
import { ref, uploadBytes } from 'firebase/storage';
import { ref as dbRef, set } from 'firebase/database';

const DashGroup = (props) => {

    let data = props.group_data;
    let index = props.index;

    const [uploadWorkerPopup, setUploadWorkerPopup] = useState(0);
    const [uploadImagePopup, setUploadImagePopup] = useState(0);
    const [renamePopup, setRenamePopup] = useState(0);
    const [active, setActive] = useState(false);
    const [pfpFile, setPfpFile] = useState(null);
    const [workerPos, setWorkerPos] = useState(null);
    const [workerName, setWorkerName] = useState(null);
    const [imgFile, setImgFile] = useState(null);
    const [groupName, setGroupName] = useState(null);

    function openAddWorkerPopup() {
        setUploadWorkerPopup(1);
    }

    function closeAddWorkerPopup() {
        setUploadWorkerPopup(0);
    }

    function openAddImagePopup() {
        setUploadImagePopup(1);
    }

    function closeAddImagePopup() {
        setUploadImagePopup(0);
    }

    function openRenamePopup() {
        setRenamePopup(1);
    }

    function closeRenamePopup() {
        setRenamePopup(0);
    }

    function toggleDWG() {
        active ? setActive(false) : setActive(true)
    }

    function generateNumber() {
        const fixDate = new Date();
        return Date.UTC(fixDate.getFullYear(), fixDate.getMonth(), fixDate.getDay(), fixDate.getHours(), fixDate.getMinutes(), fixDate.getSeconds());
    }

    function uploadWorker() {
        if (workerName != null && workerPos != null && pfpFile != null) {
            // UPLOAD CONFIRMED
            const worker_num = generateNumber();
            const imgRef = ref(storage, `auth_workers/${index}/worker-${worker_num}`);
            const dbImgRef = dbRef(database, `/new_groups/${index}/workers/${worker_num}`);

            uploadBytes(imgRef, pfpFile).then((snapshot) => {
                console.log('Upload succesful!');
                set(dbImgRef, {
                    name: workerName,
                    pos: workerPos,
                    pfp: `worker-${worker_num}`
                });
            });


        } else {
            alert("Kérem az összes mezőt töltse ki!");
        }

        closeAddWorkerPopup();

    }

    function uploadImage() {

        const count = Object.values(data.imgs).length;

        if (imgFile != null && count < 3) {
            // UPLOAD CONFIRMED
            const worker_num = generateNumber();
            const imgRef = ref(storage, `/auth_images/${index}/img-${worker_num}`);
            const dbImgRef = dbRef(database, `/new_groups/${index}/imgs/${worker_num}`);

            uploadBytes(imgRef, imgFile).then((snapshot) => {
                console.log('Upload succesful!');
                set(dbImgRef, `img-${worker_num}`);
            });

        } else if (count < 3) {
            alert("Kérem az összes mezőt töltse ki!");
        } else {
            alert("Maximum 3 képet tölthet fel egy csoporthoz");
        }

        closeAddImagePopup();
    }

    function updateGroupName() {
        // console.log(data.group_name, groupName);
        const dbGroupRef = dbRef(database, `/new_groups/${index}/`);
        set(dbGroupRef, {
            color: data.color,
            group_name: groupName,
            imgs: data.imgs,
            workers: data.workers
        });
        closeRenamePopup();
    }

    return (
        <>
        {uploadWorkerPopup === 1 ? 
        <form className="upload-popup" id={`gpu-${index}`}>
            <p className="f16-p">Dolgozó hozzáadása <br/> {`(${data.group_name} csoporthoz)`}</p>
            <div className="upload-container">
                <input id={`gpu-name-${index}`} onChange={(e) => setWorkerName(e.target.value)} className="textinput" type="text" placeholder="Név" required/>
                <input id={`gpu-pos-${index}`} onChange={(e) => setWorkerPos(e.target.value)} className="textinput" type="text" placeholder="Pozíció" required/>
                <p className="f14">Az optimális kép méret 0,5-1 MB, ennél nagyobb már nem lesz láthatóan szebb, viszont lassabban tölt be.</p>
                <input id={`gpu-pfp-${index}`} onChange={(e) => setPfpFile(e.target.files[0])} className="fileinput" type="file" required/>
            </div>
            <div className="popup-btns">
                <div className="popup-btn add" onClick={uploadWorker}>
                    <p className="f16-p noselect">Hozzáadás</p>
                </div>
                <div className="popup-btn del" onClick={closeAddWorkerPopup}>
                    <p className="f16-p noselect">Mégsem</p>
                </div>
            </div>
        </form> : ''}

        {uploadImagePopup === 1 ? 
        <form className="upload-popup" id={`ipu-${index}`}>
            <p className="f16-p">Kép hozzáadása <br/> {`(${data.group_name} csoporthoz)`}</p>
            <div className="upload-container">
                <p className="f14">Maximum 2 kép tölthető fel egy csoporthoz. Az optimális kép méret 0,5-1 MB, ennél nagyobb már nem lesz láthatóan szebb, viszont lassabban tölt be.</p>
                <input id={`ipu-img-${index}`} onChange={(e) => setImgFile(e.target.files[0])} className="fileinput" type="file" required/>
            </div>
            <div className="popup-btns">
                <div className="popup-btn add" onClick={uploadImage}>
                    <p className="f16-p noselect">Hozzáadás</p>
                </div>
                <div className="popup-btn del" onClick={closeAddImagePopup}>
                    <p className="f16-p noselect">Mégsem</p>
                </div>
            </div>
        </form> : ''}

        {renamePopup === 1 ? 
        <form className="upload-popup" id={`rpu-${index}`}>
            <p className="f16-p">{`${data.group_name} csoport átnevezése`}</p>
            <div className="upload-container">
                <input id={`rpu-name-${index}`} onChange={(e) => setGroupName(e.target.value)} className="textinput" type="text" placeholder="Csoport neve" required/>
            </div>
            <div className="popup-btns">
                <div className="popup-btn add" onClick={updateGroupName}>
                    <p className="f16-p noselect">Átnevez</p>
                </div>
                <div className="popup-btn del" onClick={closeRenamePopup}>
                    <p className="f16-p noselect">Mégsem</p>
                </div>
            </div>
        </form> : ''}
        
        <div className={`dw-group${active ? ' active' : ''}`} id={`dwg-${index}`}>
            <div className="dwg-bar">
                <div className="group-title">
                    <div className="group-color" style={{backgroundColor: `#${data.color}`}}></div>
                    <p className="f16-p noselect">{index === 5 ? `${data.group_name}` : `${data.group_name} csoport`}</p>

                </div>
                <div className="group-actions">
                    <div className="ga-add">
                        {
                            index === 5 ? '' : <>
                                <p className="f16-p noselect" onClick={openRenamePopup}>CSOPORT ÁTNEVEZÉSE</p>
                                <p className="f16-p noselect" onClick={openAddImagePopup}>KÉP HOZZÁADÁSA</p>
                            </>
                        }
                        <p className="f16-p noselect" onClick={openAddWorkerPopup}>DOLGOZÓ HOZZÁADÁSA</p>
                    </div>
                    <div className="ga-open" onClick={toggleDWG}>
                        <img src={ChevronDownIcon} alt="open" />
                    </div>
                </div>
            </div>

            {
                active ? <>
                    <div className="content-container">
                        <p className="f16-p">Dolgozók</p>
                        <div className="dwg-workers">
                            {
                                active ? Object.values(data.workers).map((worker, index) => {
                                    if (worker !== "empty") {
                                        let worker_num = Object.keys(data.workers)[index]
                                        return <DashWorker key={index} index={worker_num} group_index={props.index} worker={worker} />
                                    } else {
                                        return ''
                                    }
                                }) : ''
                            }
                        </div>
                        { index === 5 ? '' : <>
                            <p className="f16-p">Képek (Maximum 2)</p>
                                <div className="dwg-imgs">
                                    {
                                        active ? Object.values(data.imgs).map((image, index) => {
                                            if (image !== "empty") {
                                                return <DashImage key={index} index={image.split("-")[1]} group_index={props.index} image={image} />
                                            } else {
                                                return ''
                                            }
                                            }) : ''
                                    }
                                </div>
                            </>
                        }
                    </div>
                </> : ''
            }

        </div>
        </>
    )
}

export default DashGroup;