import { getDownloadURL, ref } from 'firebase/storage';
import { useEffect, useState } from 'react';
import { useSpring, animated } from 'react-spring';
import { storage } from '../firebase';

const ArrowButton = (props) => {

    const [ downloadURL, setDownloadURL ] = useState(null);

    const [ clicked, setClicked ] = useState(false);
    const [ hovered, setHovered ] = useState(false);

    const circHoveredStyle = useSpring({
        width: clicked ? 0 : 0 || hovered ? 50 : 0,
        height: clicked ? 0 : 0 || hovered ? 50 : 0,
        config: {
            duration: 250,
            reset: true
        }
    });

    const arrowHoveredStyle = useSpring({
        translateX: clicked ? 150 : 0 || hovered ? -10 : 0,
        opacity: clicked ? 0 : 1,
        config: {
            duration: 250
        }
    });

    const clickTrigger = () => {
        //clicked ? setClicked(false) : setClicked(true)
        setClicked(true);
        setTimeout(() => {
            setClicked(false);
        }, 1000);
    }

    const hoverTrigger = () => {
        setHovered(true);
    }

    const endHoverTrigger = () => {
        setHovered(false);
    }

    useEffect(() => {
        if (props.doc) {
            getUrl();
        }
    }, []);

    function getUrl() {
        const stDocRef = ref(storage, `/auth_signup_doc/${props.doc}`);

        getDownloadURL(stDocRef).then((url) => {
            setDownloadURL(url);
        })
        .catch((error) => {
            console.log(error);
        });
    }

    return (
        props.doc ? <a href={downloadURL} target="_blank" className='ccf-button clickable' onMouseEnter={hoverTrigger} onMouseLeave={endHoverTrigger} onClick={clickTrigger}>
            <p className='f24 noselect'>{props.text}</p>
            <animated.img src={props.icon} id='ccf-arrow' alt="" style={arrowHoveredStyle} />
            <animated.div className='ccf-arrow-circ' style={circHoveredStyle}></animated.div>
        </a>
        : 
        <button className='ccf-button clickable' onMouseEnter={hoverTrigger} onMouseLeave={endHoverTrigger} onClick={clickTrigger}>
            <p className='f24 noselect'>{props.text}</p>
            <animated.img src={props.icon} id='ccf-arrow' alt="" style={arrowHoveredStyle} />
            <animated.div className='ccf-arrow-circ' style={circHoveredStyle}></animated.div>
        </button>
    )
}

export default ArrowButton