import '../css/groups.css';

import { useSpring, animated } from 'react-spring';
import { useState } from 'react';

const GroupCard = (props) => {

    const { id, title, color } = props;

    const [ hovered, setHovered ] = useState(false);
    const cardHoveredStyle = useSpring({
        width: hovered ? 1000 : 0,
        height: hovered ? 1000 : 0,
        backgroundColor: `#${color}`,
        config: {
            duration: 300,
            reset: true
        }
    });

    const cardHovered = () => {
        setHovered(true);
        document.getElementById(`g-card-${id}`).addEventListener('mousemove', (event) => {setSpanPosition(event)});
    }

    const endCardHover = () => {
        setHovered(false);
        document.getElementById(`g-card-${id}`).removeEventListener('mousemove', (event) => {setSpanPosition(event)});
    }

    function setSpanPosition(event) {
        const { layerX, layerY } = event;
        document.getElementById(`g-card-hover-${id}`).style.top = `${layerY}px`;
        document.getElementById(`g-card-hover-${id}`).style.left = `${layerX}px`;
    }

    return (
        // eslint-disable-next-line
        <div className={id == 0 ? 'gc-card active' : 'gc-card'} id={`g-card-${id}`} style={{backgroundColor: id == 0 ? `#${color}` : ''}} onMouseEnter={cardHovered} onMouseLeave={endCardHover}>
            <p className='f21'>{title}</p>
            <animated.span id={`g-card-hover-${id}`} style={cardHoveredStyle}></animated.span>
        </div>
    )
}

export default GroupCard