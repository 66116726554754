import '../css/workers.css';
import workerInfo from '../assets/data/data.json';
import WorkerCard from './WorkerCard';
import NavLink from './NavLink';

const Workers = (props) => {

    let data = props.data;

    return (
        <>
            <div className="workers">
                <div className='w-title'>
                    <p className='f36'>DOLGOZÓINK</p>
                    <div className='wt-num'>
                        <p className='f48-mono noselect'>03/04</p>
                    </div>
                </div>
                <div className='w-cards'>
                    <div className='g-selector'>
                        {/* {
                            workerInfo.map((group) => {
                                const { groupId, groupName } = group;

                                return (
                                    <button className='group-navlink-container' key={groupId} onClick={() => {
                                            const mockGroupIds = [0,1,2,3,4,5];
                                            const workerRowShown = document.getElementById(`${groupId}-wr`);
                                            workerRowShown.classList.add('show');
                                            const groupIdsNotShown = mockGroupIds.filter((item) => {
                                                // eslint-disable-next-line
                                                return item != groupId;
                                            });
                                            for (let i = 0; i < groupIdsNotShown.length; i++) {
                                                const element = groupIdsNotShown[i];
                                                const workerRowNotShown = document.getElementById(`${element}-wr`);
                                                workerRowNotShown.classList.remove('show');
                                            }
                                        }}>
                                        <NavLink title={groupName} class={'nb-link-nomargin'} size={50} />
                                    </button>
                                )
                            })
                        } */}
                        {
                            Object.values(data).map((group, index) => {
                                return (
                                    <button className='group-navlink-container' key={index} onClick={() => {
                                            const mockGroupIds = [0,1,2,3,4,5];
                                            const workerRowShown = document.getElementById(`${index}-wr`);
                                            workerRowShown.classList.add('show');
                                            const groupIdsNotShown = mockGroupIds.filter((item) => {
                                                // eslint-disable-next-line
                                                return item != index;
                                            });
                                            for (let i = 0; i < groupIdsNotShown.length; i++) {
                                                const element = groupIdsNotShown[i];
                                                const workerRowNotShown = document.getElementById(`${element}-wr`);
                                                workerRowNotShown.classList.remove('show');
                                            }
                                        }}>
                                        <NavLink title={index == 5 ? `${group.group_name.toUpperCase()}` : `${group.group_name.toUpperCase()} CSOPORT`} class={'nb-link-nomargin'} size={50} />
                                    </button>
                                )
                            })
                        }
                    </div>
                    {/* {
                        workerInfo.map((group) => {
                            const { groupId, groupName, workers } = group;

                            return (
                                // eslint-disable-next-line
                                <div key={groupName} id={`${groupId}-wr`} className={groupId == 0 ? 'workerrow-container show' : 'workerrow-container'}>
                                    <div className='w-cards-row' id={`group-${groupId}-cards`}>
                                        {workers.map((worker) => {
                                            // return <WorkerCard key={worker.id} id={worker.id} url={worker.url} name={worker.name} occupation={worker.occupation} />
                                            return <WorkerCard key={worker.id} id={worker.id} name={worker.name} occupation={worker.occupation} />

                                        })}
                                    </div>
                                </div>
                            )
                        })
                    } */}
                    {
                        Object.values(data).map((group, index) => {
                            return (
                                // eslint-disable-next-line
                                <div key={group.group_name} id={`${index}-wr`} className={index == 0 ? 'workerrow-container show' : 'workerrow-container'}>
                                    <div className='w-cards-row' id={`group-${index}-cards`}>
                                        {Object.values(group.workers).map((worker, workerIndex) => {
                                            return worker == 'empty' ? '' : <WorkerCard key={workerIndex} index={index} id={worker.pfp} name={worker.name} occupation={worker.pos} />
                                        })}
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        </>
    )
}

export default Workers