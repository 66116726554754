import '../css/workers.css';

import { useSpring, animated } from 'react-spring';
import { useState, createRef } from 'react';

import { ref as stRef, getDownloadURL } from 'firebase/storage';
import { storage } from '../firebase';
import { useEffect } from 'react';
import { decodeBlurHash } from 'fast-blurhash';

const WorkerCard = (props) => {
    
    const { index, id, name, occupation } = props;

    const blurredImage = decodeBlurHash("LDM?-*0J%yNG~Uxtx^aKSd$*WVxb", 200, 200);

    const [ hovered, setHovered ] = useState(false);
    const cardHoveredStyle = useSpring({
        width: hovered ? 1000 : 0,
        height: hovered ? 1000 : 0,
        config: {
            duration: 400,
            reset: true
        }
    });

    const textHoveredStyle = useSpring({
        opacity: hovered ? 1 : 0,
        config: {
            duration: 400,
            reset: true
        }
    });

    const cardHovered = () => {
        setHovered(true);
        document.getElementById(`w-card-${id}`).addEventListener('mousemove', (event) => {setSpanPosition(event)});
    }

    const endCardHover = () => {
        setHovered(false);
        document.getElementById(`w-card-${id}`).removeEventListener('mousemove', (event) => {setSpanPosition(event)});
    }

    function setSpanPosition(event) {
        const { layerX, layerY } = event;
        document.getElementById(`w-card-hover-${id}`).style.top = `${layerY}px`;
        document.getElementById(`w-card-hover-${id}`).style.left = `${layerX}px`;
    }

    const imgRef = createRef(null);
    const cardRef = createRef(null);

    useEffect(() => {

        const width = 200;
        const height = 200;

        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
        const imageData = ctx.createImageData(width, height);
        imageData.data.set(blurredImage);
        ctx.putImageData(imageData, 0, 0);
        cardRef.current.append(canvas);

        const storageRef = stRef(storage, `/auth_workers/${index}/${id}`);
        getDownloadURL(storageRef).then((url) => {
            if (imgRef.current) {
                imgRef.current.setAttribute('src', url);
            }
            // document.getElementById(`w-card-${id}`).setAttribute('src', url);
        }).catch(err => {
            console.log(err);
        });

        // eslint-disable-next-line
    }, [imgRef, cardRef])



    return (
        <div className='w-card' ref={cardRef} id={`w-card-${id}`} onMouseEnter={cardHovered} onMouseLeave={endCardHover}>
            <animated.div className='w-card-text noselect' style={textHoveredStyle}>
                <p className='f21'>{name}</p>
                <div className='divider-dot'></div> 
                <p className='f21'>{occupation}</p>
            </animated.div>
            <animated.span id={`w-card-hover-${id}`} style={cardHoveredStyle}></animated.span>
            <img ref={imgRef} src="" alt="" />
        </div>
    )
}

export default WorkerCard