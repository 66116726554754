import '../css/foundation.css';

const FoundationCard = () => {

    return (
        <>
            <div className='foundation' id='foundation-card'>
                <p className='f36 desktop'>"GYERMEKEINKBEN ÉRLELŐDIK A JÖVŐ" ALAPÍTVÁNY</p>
                <p className='f36 mobile'>"GYERMEKEINK-BEN ÉRLELŐDIK A JÖVŐ" ALAPÍTVÁNY</p>
                <p className='f16-p'>Nevelőközösségünk kezdeményezésére 1992-ben kezdte meg működését a „Gyermekeinkben érlelődik a jövő” alapítványunk. Célunk volt, hogy a szűkös költségvetési keretet kiegészíthessük, illetve fejleszthessük a csoportszobáink eszközeit, a gyermekek fejlesztő játékait.</p>
                <p className='f16-p'>Továbbra is várjuk támogató segítségüket, és felajánlásaikat az alábbi számlaszámra:</p>
                <div className='f-info desktop'>
                    <div className='fi-col'>
                        <p className='f16-p'><b>SZÁMLASZÁM:</b></p>
                        <p className='f16-p'><b>ADÓSZÁM:</b></p>
                    </div>
                    <div className='fi-col'>
                        <p className='f16-p'>10402427-24218232-00000000</p>
                        <p className='f16-p'>19034975-1-02</p>
                    </div>
                </div>
                <div className='f-info mobile'>
                    <div className='fi-col'>
                        <p className='f16-p'><b>SZÁMLASZÁM:</b></p>
                        <p className='f16-p'>10402427-24218232-00000000</p>
                        <p className='f16-p'><b>ADÓSZÁM:</b></p>
                        <p className='f16-p'>19034975-1-02</p>
                    </div>
                </div>
            </div>
        </>
    )
}

export default FoundationCard