import '../css/groups.css';
import groupInfo from '../assets/data/data.json';
import GroupCard from './GroupCard';
import GroupImage from './GroupImage';

const Groups = (props) => {

    const data = props.data;

    return (
        <>
            <div className='groups' id='groups'>
                <div className='g-title'>
                    <p className='f36'>CSOPORTJAINK</p>
                    <div className='gt-num'>
                        <p className='f48-mono noselect'>02/04</p>
                    </div>
                </div>
                <div className='g-content'>
                    <p className='f16-p'>Nevelőközösségünk kezdeményezésére, 1992-ben – az általunk befizetett induló vagyonnal – kezdte meg működését a „Gyermekeinkben érlelődik a jövő” alapítványunk. Célunk volt, hogy a szűkös költségvetési keretet kiegészíthessük, illetve fejleszthessük a csoportszobáink eszközeit, a gyermekek fejlesztő játékait.</p>
                    <br />
                    <p className='f16-p'>2017 tavaszán óvodánk pályázatot nyújtott be és elnyerte a "Zöld Óvoda" címet. Fontosnak tartjuk és kiemelt helyen kezeljük, már kisgyermekkorban a környezettudatos nevelést a játék, a tanulás és a tevékenykedtetés eszközeivel.</p>
                </div>
                <div className='g-cards'>
                    {/* {
                        groupInfo.map((group) => {
                            const { groupId, groupName } = group;
                            return ( groupId === 5 ? '':
                                <button className='group-card-container' key={groupId} onClick={() => {
                                        const mockGroupIds = [0,1,2,3,4];
                                        const imgsRowShown = document.getElementById(`${groupId}-gi`);
                                        const gCardShown = document.getElementById(`g-card-${groupId}`);
                                        imgsRowShown.classList.add('show');
                                        gCardShown.classList.add('active');
                                        const groupIdsNotShown = mockGroupIds.filter((item) => {
                                            // eslint-disable-next-line
                                            return item != groupId;
                                        });
                                        for (let i = 0; i < groupIdsNotShown.length; i++) {
                                            const element = groupIdsNotShown[i];
                                            const imgsRowNotShown = document.getElementById(`${element}-gi`);
                                            const gCardNotShown = document.getElementById(`g-card-${element}`);
                                            gCardNotShown.classList.remove('active');
                                            imgsRowNotShown.classList.remove('show');
                                        }
                                    }}>
                                    <GroupCard id={groupId} title={groupName}/>
                                </button>
                            )
                        })
                    } */}
                    {
                        Object.values(data).map((group, index) => {
                            return ( index == 5 ? '' : 
                                <button className='group-card-container' key={index} onClick={() => {
                                        const mockGroupIds = [0,1,2,3,4];
                                        const imgsRowShown = document.getElementById(`${index}-gi`);
                                        const gCardShown = document.getElementById(`g-card-${index}`);
                                        imgsRowShown.classList.add('show');
                                        gCardShown.classList.add('active');
                                        gCardShown.style.setProperty("background-color", `#${group.color}`)
                                        const groupIdsNotShown = mockGroupIds.filter((item) => {
                                            // eslint-disable-next-line
                                            return item != index;
                                        });
                                        for (let i = 0; i < groupIdsNotShown.length; i++) {
                                            const element = groupIdsNotShown[i];
                                            const imgsRowNotShown = document.getElementById(`${element}-gi`);
                                            const gCardNotShown = document.getElementById(`g-card-${element}`);
                                            gCardNotShown.classList.remove('active');
                                            imgsRowNotShown.classList.remove('show');
                                            gCardNotShown.style.setProperty("background-color", `#FFF7EC`)
                                        }
                                    }}>
                                    <GroupCard id={index} title={`${group.group_name.toUpperCase()} CSOPORT`} color={group.color}/>
                                </button>
                            )
                        })
                    }
                </div>
                {/* {
                    groupInfo.map((group) => {
                        // eslint-disable-next-line
                        const { groupId, groupName, images } = group;
                        return ( 
                            groupId === 5 ? '':
                                <div key={groupId} id={`${groupId}-gi`} className={groupId === 0 ? 'group-imgs-row show' : 'group-imgs-row'}>
                                    {
                                        images.map((img) => {
                                            return (
                                                <div key={img.id} style={{ backgroundImage: `url(${img.url})` }} alt={img.alt} />
                                            )
                                        })
                                    }
                                </div>)
                    })
                } */}
                {
                    Object.values(data).map((group, index) => {
                        return ( 
                            index === 5 ? '':
                                <div key={index} id={`${index}-gi`} className={index === 0 ? 'group-imgs-row show' : 'group-imgs-row'}>
                                    {
                                        Object.values(group.imgs).map((img) => {
                                            if (img != "empty") {
                                                return (
                                                    <GroupImage key={img} index={index} img={img} />
                                                )
                                            }
                                        })
                                    }
                                </div>)
                    })
                }
            </div>
        </>
    )
}

export default Groups