import '../css/groups.css';
import broccoli from '../assets/icons/broccoli.svg';
import guitar from '../assets/icons/guitar.svg';
import forest from '../assets/icons/forest.svg';

import { useSpring, animated } from 'react-spring';
import { useState } from 'react';

const AboutCard = (props) => {

    const { id, title } = props;

    let icon;
    if (id === 0) {
        icon = broccoli
    } else if (id === 1) {
        icon = guitar
    } else {
        icon = forest
    }

    const [ hovered, setHovered ] = useState(false);

    const hoveredCard = useSpring({
        width: hovered ? 1000 : 0,
        height: hovered ? 1000 : 0,
        config: {
            duration: 350,
            reset: true
        }
    });

    const cardHovered = () => {
        setHovered(true);
        document.getElementById(`card-${id}`).addEventListener('mousemove', (event) => {setSpanPosition(event)});
    }

    const endCardHover = () => {
        setHovered(false);
        document.getElementById(`card-${id}`).removeEventListener('mousemove', (event) => {setSpanPosition(event)});
    }

    function setSpanPosition(event) {
        const { layerX, layerY } = event;
        document.getElementById(`card-hover-${id}`).style.top = `${layerY}px`;
        document.getElementById(`card-hover-${id}`).style.left = `${layerX}px`;
    }

    

    return (
        <div className='ac-card' id={`card-${id}`} onMouseEnter={cardHovered} onMouseLeave={endCardHover}>
            <img src={icon} alt="" />
            <p className='f21'>{title}</p>
            <animated.span id={`card-hover-${id}`} style={hoveredCard}></animated.span>
        </div>
    )
}

export default AboutCard