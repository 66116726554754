import { VideoPlayer } from '@aracsidev/video-player';
import '../css/about.css';
import AboutCard from './AboutCard';
import AboutSection from './AboutSection';
// import VideoPlayer from './VideoPlayer';

import { useState } from "react";
import { useSpring, animated } from "react-spring";

const About = () => {

    const [ sectionId, setSectionId ] = useState(0);
    const [ isActive, setIsActive ] = useState(false);
    // const [ hovered, setHovered ] = useState(false);

    const aboutContent = [
        {
            id: 0,
            title: "EGÉSZSÉGES ÉLETMÓD"
        },
        {
            id: 1,
            title: "TEHETSÉGGONDOZÁS"
        },
        {
            id: 2,
            title: "KÖRNYEZETTUDATOSSÁG"
        }
    ]

    const mountStyle = useSpring({
        y: isActive ? 0 : 200,
        opacity: isActive ? 1 : 0,
        config: {
            friction: 15,
            reset: true
        }
    });

    return (
        <>
            <div className='about' id='about'>
                <div className='a-title'>
                    <p className='f36'>RÓLUNK</p>
                    <div className='at-num'>
                        <p className='f48-mono noselect'>01/04</p>
                    </div>
                </div>
                <div className='a-content'>
                    <div className='ac-text'>
                        <p className='f16-p'>Óvodánk Pécs belvárosában, a Szigeti városrészben helyezkedik el. Belvárosi elhelyezkedésünk révén – jól megközelíthetőek vagyunk busszal is – egész Pécsről érkeznek gyermekek intézményünkbe. </p>
                        <br />
                        <p className='f16-p'>Pécsi óvodánk épülete, egy a XVIII. században épült patinás barokk stílusú műemlék, melyet többször átépítettek, felújítottak. Fokozatosan, végül 1974-ben bővítették 6 csoportos óvodává. Az épület stílusával jól harmonizáló csoportszobák és más helyiségek egyedi hangulatát óvodapedagógusaink kreativitásának, leleményességének köszönhetjük. 126 kisgyermek járhat hozzánk, a hat csoportszobánk, alapterülettől függően 19-25 fős gyermeklétszámmal működnek. </p>
                        <br />
                        <p className='f16-p'>Udvarunk zárt, fás, ligetes. Telepítése, elrendezése és biztonságos játékai, ideális céljaink megvalósításhoz, gazdag játék és mozgáslehetőséget biztosítanak.</p>
                    </div>
                    <div className='ac-video'>
                        <VideoPlayer src="video/about_video.mp4"/>
                    </div>
                </div>
                <div className='a-cards'>
                    {
                        aboutContent.map((content) => {
                            
                            const { id, title } = content;
                            
                            return ( id === 3 ? '' :
                                <button key={id} className='ac-card-container' onClick={() => {
                                    setSectionId(id);
                                    setIsActive(true);
                                    const mockAboutIds = [0,1,2]
                                    const acCardShown = document.getElementById(`card-${id}`)
                                    acCardShown.classList.add('active')
                                    const aboutIdsNotShown = mockAboutIds.filter((item) => {
                                        // eslint-disable-next-line
                                        return item != id
                                    })
                                    for (let i = 0; i < aboutIdsNotShown.length; i++) {
                                        const element = aboutIdsNotShown[i]
                                        const acCardNotShown = document.getElementById(`card-${element}`)
                                        acCardNotShown.classList.remove('active')
                                    }
                                }}>
                                    <AboutCard id={id} title={title}/>
                                </button>
                            )
                        })
                    }
                </div>
                {isActive && <animated.div className='a-section-container' style={mountStyle}>
                    <AboutSection id={sectionId} />
                    <div className='f-close-btn' onClick={() => {
                        setIsActive(false);
                        const acCards = document.getElementsByClassName('ac-card')
                        for (var i = 0; i < acCards.length; i++) {
                            acCards[i].classList.remove('active')
                        }
                    }}></div>
                </animated.div>}
            </div>
        </>
    )
}

export default About