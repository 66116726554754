import '../css/fonts.css';
import '../css/main.css';
import '../css/media_queries.css';

import { auth } from '../firebase';

import SignIn from '../components/Auth/SignIn';
import Dashboard from '../components/Auth/Dashboard';

import { useEffect, useState } from 'react';
import { onAuthStateChanged } from 'firebase/auth';

const AdminPage = () => {

    const [user, setUser] = useState(null);
    useEffect(() => {
        const listen = onAuthStateChanged(auth, (auth_user) => {
            if (auth_user) {
                setUser(auth_user);
            } else {
                setUser(null);
            }
        });

        return () => {
            listen();
        }

    }, [])

    return (
        <>
            {user ? <Dashboard user={user} /> : <SignIn />}
        </>
    );
}

export default AdminPage;