import { useState } from 'react';
import '../../css/login.css';

import { auth } from '../../firebase';
import { signInWithEmailAndPassword } from 'firebase/auth';

const SignIn = () => {

    const [email, setEmail] = useState('');
    const [passw, setPassw] = useState('');

    const signIn = (e) => {
        e.preventDefault();
        signInWithEmailAndPassword(auth, email, passw).then((userCredentials) => {
            console.log("Bejelentkezés sikeres!");
        }).catch((err) => {
            console.log(err);
        });
    }

    return (
        <>
            <div className="login">
                <div className='title'>
                    <p className='f24'>ADMIN BEJELENTKEZÉS</p>
                    <p className="f16-p">Ez az óvoda adminisztrációs oldala, ha nem kapott hozzáférést, kérjük lépjen vissza a főoldalra.</p>
                </div>
                <form onSubmit={signIn} className="login-form">
                    <input type="email" placeholder='Email cím' value={email} onChange={(e) => setEmail(e.target.value)}/>
                    <input type="password" placeholder='Jelszó' value={passw} onChange={(e) => setPassw(e.target.value)}/>
                    <button type="submit">Bejelentkezés</button>
                </form>
            </div>
        </>
    );
}

export default SignIn;