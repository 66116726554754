// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getStorage, ref } from "firebase/storage";
import { getDatabase } from "firebase/database";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyDPbntN7LT3b4H7RSHaO442bjNCYInx8ug",
  authDomain: "nagyjenoovoda.firebaseapp.com",
  databaseURL: "https://nagyjenoovoda-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "nagyjenoovoda",
  storageBucket: "nagyjenoovoda.appspot.com",
  messagingSenderId: "788196801571",
  appId: "1:788196801571:web:b74dec3cf2adef29160596",
  measurementId: "G-XJF1V6RGBY"
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
export const analytics = getAnalytics(app);
export const storage = getStorage(app);
export const storageRef = ref(storage, '/workers_new/worker-0.jpeg');
export const database = getDatabase(app);
export const auth = getAuth(app);