import '../../css/dashboard.css';

import { signOut } from "firebase/auth";
import { auth } from "../../firebase";
import DashGroups from './DashGroups';

const Dashboard = (user) => {

    const userSignOut = () => {
        signOut(auth).then(() => {
            console.log("Kijelentkezés sikeres!");
        }).catch((err) => {
            console.log(err);
        });
    }

    return (
        <div className='dashboard'>
            <div className="dashboard-menu">
                <div className="dm-container">
                    {user ? <p className="noselect">{`Bejelentkezve: ${user.user.email}`}</p>  : ''}
                </div>
                <div className="menu-btn" onClick={userSignOut}>
                    <p className='noselect'>KIJELENTKEZÉS</p>
                </div>
            </div>
            <DashGroups />
        </div>
    )
}

export default Dashboard;