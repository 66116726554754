import '../css/navbar.css';

import { useSpring, animated } from 'react-spring';
import { useState } from 'react';

const NavLink = (props) => {

    /*
        In Navbar.js, the title property is passed here.
        Using this you can rename the links.

        When you hover over .nb-link (which is the container <div>),
        the .nb-link-circ (the circle behind the container <div>) is shown.

        Use the hoveredStyle to adjust how .nb-link-circ behaves once
        you hover over .nb-link.
    */

    const [ hovered, setHovered ] = useState(false);

    const hoveredStyle = useSpring({
        width: hovered ? props.size : 0,
        height: hovered ? props.size : 0,
        config: {
            duration: 100,
            reset: true
        }
    });

    const hoverTrigger = () => {
        setHovered(true);
    }

    const endHoverTrigger = () => {
        setHovered(false);
    }

    return (
        <div className={props.class} onMouseEnter={hoverTrigger} onMouseLeave={endHoverTrigger}>
            <p className='f16-p noselect'>{props.title}</p>
            <animated.div className='nb-link-circ' style={hoveredStyle}></animated.div>
        </div>
    )
}

export default NavLink