import { getDownloadURL, ref } from "firebase/storage";
import { storage } from "../firebase";
import { useEffect } from "react";

const GroupImage = (props) => {

    let index = props.index;
    let img = props.img;

    const imageRef = ref(storage, `/auth_images/${index}/${img}`)
    useEffect(() => {
        getDownloadURL(imageRef).then(url => {
            document.getElementById(img).style.setProperty("background-image", `url(${url})`)
        })
        .catch((error) => {
            console.log(error);
        });
    }, []);

    return (
        <div key={img} id={img}></div>
    )
}

export default GroupImage;