import '../css/title.css';
import logo from '../assets/icons/njo_logo.svg';
import arrow from '../assets/icons/arrow.svg';

import { Link } from 'react-scroll';
import { useSpring, animated } from 'react-spring';
import { useState } from 'react';

const Title = (props) => {

    /*
        When you hover over .ts-arrow (which is the container <div>),
        the .arrow-circ (the circle behind the container <div>) is shown
        and the #arrow slides up.
        
        When you click on .ts-arrow, the .arrow-circ disappears,
        the #arrow slides down and the window is scrolled. 
        (This is permanent, has to be reactivated by the
        resetClickTrigger arrow function, once the scroll occurs)

        Use the circHoveredStyle and the arrowHoveredStyle to adjust 
        these behaviours.
    */

    let data = props.data;

    const [ clicked, setClicked ] = useState(false);
    const [ hovered, setHovered ] = useState(false);

    const circHoveredStyle = useSpring({
        width: clicked ? 0 : 0 || hovered ? 70 : 0,
        height: clicked ? 0 : 0 || hovered ? 70 : 0,
        config: {
            duration: 250,
            reset: true
        }
    });

    const arrowHoveredStyle = useSpring({
        translateY: clicked ? 150 : 0 || hovered ? -10 : 0,
        opacity: clicked ? 0 : 1,
        config: {
            duration: 250
        }
    });

    const clickTrigger = () => {
        //clicked ? setClicked(false) : setClicked(true)
        setClicked(true);
        setTimeout(() => {
            setClicked(false);
        }, 2000);
    }

    const hoverTrigger = () => {
        setHovered(true);
    }

    const endHoverTrigger = () => {
        setHovered(false);
    }

    return (
        <>
            <div className='titlescreen section'>
                <div className='ts-title'>
                    <img src={logo} alt="" />
                    <p className='f16'>NAGY JENŐ ÓVODA</p>
                    <h1 className='f72 noselect'>Tárt karokkal várunk!</h1>
                </div>
                <Link className='ts-arrow clickable' onMouseEnter={hoverTrigger} onMouseLeave={endHoverTrigger} onClick={clickTrigger} to={data.show ? `news-section` : `about`} smooth={true} duration={1000} offset={-120}>
                    <p className='f24 noselect'>Nézz be hozzánk!</p>
                    <animated.img src={arrow} id='arrow' alt="" style={arrowHoveredStyle} />
                    <animated.div className='arrow-circ' style={circHoveredStyle}></animated.div>
                </Link>
            </div>
        </>
    )
}

export default Title