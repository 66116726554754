import '../css/contact.css';
import bubble from '../assets/icons/rounded-bubble-top.svg';

import { useSpring, animated } from 'react-spring';
import { useState } from 'react';

const ContactIcon = (props) => {

    const [ hovered, setHovered ] = useState(false);

    const hoveredStyle = useSpring({
        width: hovered ? 40 : 0,
        height: hovered ? 40 : 0,
        config: {
            duration: 100,
            reset: true
        }
    });

    const hoveredInfoStyle = useSpring({
        opacity: hovered ? 1 : 0,
        config: {
            duration: 100,
            reset: true
        }
    });

    const hoverTrigger = () => {
        setHovered(true);
    }

    const endHoverTrigger = () => {
        setHovered(false);
    }

    return (
        <div className="ct-icon">
            <a href={props.href} target={props.newPage == 1 ? '_blank' : ''} rel='noreferrer' >
                <img className='ct-img' src={props.img} alt={props.alt} onMouseEnter={hoverTrigger} onMouseLeave={endHoverTrigger}/>
            </a>
            <animated.div className='ct-icon-info' style={hoveredInfoStyle} >
                <p className='f16-p noselect'>{props.info}</p>
                <img src={bubble} alt="" />
            </animated.div>
            <animated.div className='cti-link-circ' style={hoveredStyle}></animated.div>
        </div>
    )
}

export default ContactIcon