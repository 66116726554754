import DeleteIcon from '../../assets/icons/delete.svg';

import { ref, getDownloadURL, deleteObject } from 'firebase/storage';
import { ref as dbRef, set } from 'firebase/database';
import { database, storage } from '../../firebase';
import { useEffect } from 'react';

const DashWorker = (props) => {

    let index = props.index;
    let group_index = props.group_index;
    let data = props.worker;

    const workerRef = ref(storage, `/auth_workers/${group_index}/${data.pfp}`);
    const dbWorkerRef = dbRef(database, `/new_groups/${group_index}/workers/${index}`);

    useEffect(() => {
        getDownloadURL(workerRef).then((url) => {
            document.getElementById(`dwgwi-${index}`).setAttribute('src', url);
        })
        .catch((error) => {
            console.log(error);
        });
    }, []);

    function deleteWorker() {
        if(window.confirm("Biztosan törölni szeretné ezt a képet?")) {
            set(dbWorkerRef, null);
            deleteObject(workerRef).then(() => {
                console.log("File successfully deleted.");
            }).catch((error) => {
                console.log(error);
            });
        }
    }

    return (
        <div className="dwg-worker" id={`dwgw-${index}`}>
            <div className="dwg-hover-container">
                <img src="" alt="" className='dwgwi' id={`dwgwi-${index}`} />
                <div className="img-container">
                    <img className='delete-icon' src={DeleteIcon} alt="" onClick={deleteWorker}/>
                </div>
            </div>
            <div className="dwgw-name">
                <div className="dwgw-container">
                    <p className="f16-p">{data.name}</p>
                    <p className="f16-p">{data.pos}</p>
                </div>
            </div>
        </div>
    )
}

export default DashWorker;