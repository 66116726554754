import '../css/fonts.css';
import '../css/main.css';
import '../css/home.css';
import '../css/media_queries.css';

import Navbar from '../components/Navbar';
import Title from '../components/Title';
import News from '../components/News';
import About from '../components/About';
import Groups from '../components/Groups';
import Workers from '../components/Workers';
import Contact from '../components/Contact';
import FoundationCard from '../components/FoundationCard';
import { database } from '../firebase';
import { onValue, ref } from 'firebase/database';
import { useEffect, useState } from 'react';

const HomePage = (props) => {

    document.title = props.title;

    const [data, setData] = useState([]);
    const [docData, setDocData] = useState([]);
    const dbRef = ref(database, '/new_groups/');
    const dbDocRef = ref(database, "/signup_document/");

    useEffect(() => readData(), []);

    function readData() {
        onValue(dbRef, (snapshot) => {
            if (snapshot.exists) {
                setData(Object.values(snapshot.val()));
            }
        });

        onValue(dbDocRef, (snapshot) => {
            if (snapshot.exists) {
                setDocData(snapshot.val());
            }
        });
    }

    return (
        
        <div className='container'>
            <Navbar />
            <Title data={docData} />
            <News data={docData} />
            <About />
            <FoundationCard />
            <Groups data={data} />
            <Workers data={data} />
            <Contact />
        </div>
    )
}

export default HomePage