import '../css/news.css'

import arrow from '../assets/icons/arrow.svg'
import doc from '../assets/documents/doc_beiratas_2023.pdf'

import ArrowButton from './ArrowButton'
import { onValue, ref } from 'firebase/database'
import { ref as stRef } from 'firebase/storage'
import { database, storage } from '../firebase'
import { useEffect, useState } from 'react'

const News = (props) => {

    let data = props.data;

    return (
        <>
            {
                data.show ? 
                <div className="news-section" id="news-section">
                    <p className="f36">Beiratás</p>
                    <p className="f21">{data.text}</p>
                    <ArrowButton text="DOKUMENTUM" icon={arrow} doc={data.doc} />
                </div> : ''
            }
        </>
    )
}

export default News